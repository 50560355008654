import React from 'react';
import Layout from './../components/shared/Layout';
import AboutComponent from './../components/about/AboutComponent';

const AboutPage = () => (
    <Layout title="About Cafe Living">
        <AboutComponent />
    </Layout>
)

export default AboutPage;