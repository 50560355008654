import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container } from './../../elements/SharedElements';
import { About, Wrapper, Header } from './../../elements/AboutElements';

const AboutComponent = () => {
    
    const assets = useStaticQuery(graphql`
            query contentful {
                allContentfulAsset( filter: {
                    title: {eq: "cafe-living-about"}
                  }) {
                    edges {
                    node {
                        file {
                            url
                        fileName
                            }
                        }
                    }
                }
            }
    `)

    const backgroundImage = assets.allContentfulAsset.edges.filter((edge) => {
        return edge.node.file.fileName === "cafe-living-about.jpg"
    });
    const aboutSide = assets.allContentfulAsset.edges.filter((edge) => {
        return edge.node.file.fileName !== "cafe-living-about.jpg"
    });

    return (
        <About>
            <Header style={{backgroundImage: `url(${backgroundImage[0].node.file.url})`}}>
                <h1>About Cafe living</h1>
                <div></div>
            </Header>
            <Container>
                <Wrapper>
                    <div className="content">
                        <h2>About</h2>
                        <p>
                        Café Living explores a plethora of eateries within the South African landscape. From stylish 5 star dining to budget meals including African cuisine, we at Café Living aim to connect communities one article at a time.                         </p>
                        <p>
                        The origin of this journey began during varsity days with trips to Greenside for happy hour cocktails & weekend aways using alternative budget trips to unwind after long stressful weeks of examinations and assignment deadlines.                        </p>
                        <p>
                        With a variety of places to eat, bars to booze up and great scenery to make new friends, cafe living ensures that everyone is accommodated for. Each month the site introduces great new places to try out and offers a friendly review of each of the eateries.
                        </p>
                        <p>
                        The aim ? To connect food communities and foodfluencers                         
                        </p>
                        <p>
                        Bon appetite
                        </p>
                        <h2 className="last">Contact Details</h2>
                        <div className="email">
                            Email: <a href="mailto:hello@cafeliving.co.za">hello@cafeliving.co.za</a>
                        </div>  
                    </div>
                    <div className="image" style={{backgroundImage: `url(${aboutSide[0].node.file.url})`}}>
    
                    </div>
                </Wrapper>      
            </Container>
        </About>
    )
    
}

export default AboutComponent;