import styled from 'styled-components';

export const About = styled.section`
    padding: 0 0 2rem;
`

export const Header = styled.header`
    background-color: ${ props => props.theme.darkColor};
    opacity: 0.9;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    background-position: center center;
    position: relative;
    z-index: 2;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: ${ props => props.theme.darkColor};
        opacity: 0.5;
        z-index: 1;
    }

    h1 {
        color: ${ props => props.theme.lightColor};
        font-size: 3rem;
        position: relative;
        z-index: 2;

        @media screen and (max-width: 500px){
            font-size: 2rem;
        }
    }

    div {
        width: 5rem;
        border-top: 2px solid ${ props => props.theme.primaryColor};
        content: "";
        position: relative;
        z-index: 2;
    }
`

export const Wrapper = styled.div`

padding: 2rem 1rem;
    
    display: flex;
    flex-wrap: wrap;
    .image {
        background-size: cover;
        background-position: center center;
        flex-basis: 48%;
        border-radius: 0.5rem;
        @media screen and (max-width: 500px){
            flex-basis: 98%;
            height: 30rem;
            margin-top: 1rem;
        }
    }

    .content {
        flex-basis: 48%;
        margin-right: 1rem;
        @media screen and (max-width: 500px){
            flex-basis: 98%;
            margin-right: 0;
        }
        h2 {
            color: ${ props => props.theme.darkGreyColor};
            font-size: 1.8rem;

            &.last{
                margin-top: 1rem;
                margin-bottom: 0.5rem;
            }
        }
        p {
            margin-bottom: 0.6rem;
            font-size: 1.1rem;
            font-weight: 400;
            color: ${ props => props.theme.darkColor};
        }

        .email {
            a {
                color: ${ props => props.theme.primaryColor };
                font-weight: 800;
            }

        }
    }

`